<template>
	<v-container>
		<div class="blog-component mini-spacer">
			<!-- -----------------------------------------------
            Start Blog Text
        ----------------------------------------------- -->
			<v-row justify="center">
				<v-col cols="12" sm="10" md="9" lg="7">
					<div class="text-center">
						<h2 class="ui-title font-weight-bold">Looking for?</h2>

					</div>
				</v-col>
			</v-row>

			<v-row justify="center">
				<v-col cols="6" md="6" sm="6" lg="6" v-for="dest in curatedListings" :key="dest.title">
					<NuxtLink :to="dest.slug === '/airport-transfers' ? dest.slug : `/listings${dest.slug}`">
						<div class="image-container blog-card overflow-hidden hover-card center-card text-center">
							<NuxtImg loading="lazy" format="webp" quality="75" :src="dest.img"
								:alt="`Image of ${dest.title}`" width="320px" rounded fit="cover" />
							<div class="bg-text">
								<h1>{{ dest.title }}</h1>
							</div>
						</div>
					</NuxtLink>
				</v-col>
			</v-row>
		</div>
	</v-container>
</template>

<script setup lang="ts">
const listingStore = useListingsStore();
// Using the same property name as was in the original component
const { curratedListings: curatedListings } = storeToRefs(listingStore);
</script>

<style scoped>
.image-container {
	position: relative;
	width: 100%;
	max-height: 230px;
	/* Adjust height as needed */
	/* Replace with your image path */
	background-size: cover;
	background-position: center;
	color: white;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.7);
}

.bg-text {
	background-color: rgb(0, 0, 0);
	/* Fallback color */
	background-color: rgba(0, 0, 0, 0.4);
	/* Black w/opacity/see-through */
	color: white;
	font-weight: bold;
	border-radius: 10px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	width: 80%;
	padding: 20px;
	text-align: center;
}

.image-container h1 {
	font-size: 0.75rem;
	/* Adjust font size */
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
	color: white;
	/* Optional: text shadow for better contrast */
}
</style> 