<template>
  <div class="bg-extra-light py-10">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="10" md="9" lg="7">
          <div class="text-center mb-8">
            <h2 class="ui-title font-weight-bold">Why Choose Us</h2>
            <p class="text-muted">Experience Palawan with the island's most trusted travel partner</p>
          </div>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col v-for="(feature, index) in features" :key="index" cols="12" sm="6" md="4" class="mb-6">
          <div class="text-center">
            <v-icon :icon="feature.icon" size="x-large" color="primary" class="mb-4"></v-icon>
            <h3 class="text-h6 font-weight-bold mb-2">{{ feature.title }}</h3>
            <p class="text-body-2">{{ feature.description }}</p>
          </div>
        </v-col>
      </v-row>
      
      <v-row justify="center" class="mt-6">
        <v-col cols="12" sm="6" md="4" class="text-center">
          <v-btn to="/tours" color="primary" size="large" class="px-6" rounded>
            Browse Our Tours
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup>
const features = [
  {
    icon: 'mdi-check-decagram',
    title: 'Local Expertise',
    description: 'Our team of local experts knows Palawan inside out, ensuring you get authentic and memorable experiences.'
  },
  {
    icon: 'mdi-currency-usd',
    title: 'Best Price Guarantee',
    description: 'We offer competitive pricing and transparent booking with no hidden fees or surprise charges.'
  },
  {
    icon: 'mdi-shield-check',
    title: 'Safe & Reliable',
    description: 'All our tours and services follow strict safety protocols, with licensed guides and quality equipment.'
  },
  {
    icon: 'mdi-account-group',
    title: 'Personalized Service',
    description: 'We customize each experience to meet your unique preferences and travel requirements.'
  },
  {
    icon: 'mdi-star',
    title: 'Top-Rated Experience',
    description: 'With over 1,000 5-star reviews, our commitment to excellence speaks for itself.'
  },
  {
    icon: 'mdi-phone-in-talk',
    title: '24/7 Support',
    description: 'Our dedicated support team is available around the clock to assist with any questions or concerns.'
  }
];
</script>

<style scoped>
.bg-extra-light {
  background-color: #f8f9fa;
}
</style> 