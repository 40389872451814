<script setup lang="ts">
const store = useBookingStore();
const { reviews } = storeToRefs(store);

// Improved head with additional metadata for better SEO
useHead({
	htmlAttrs: {
		lang: 'en',
	},
	script: [
		{
			key: 'jsonld-business-only',
			type: 'application/ld+json',
			children: businessOnly(reviews.value),
		},
	],
	link: [
		{ rel: 'canonical', href: 'https://www.diskubrepalawan.com' },
	],
});

// Enhanced SEO metadata
useSeoMeta({
	title: 'Diskubre Travel Palawan - Official Website',
	ogTitle: 'Diskubre Travel Official Website',
	description: 'Palawan\'s most trusted travel agency for booking tours, transportation and activities. Book with us today!',
	ogDescription: 'Palawan\'s most trusted travel agency for booking tours, transportation and activities. Book with us today!',
	ogImage: [{ url: 'https://www.diskubrepalawan.com/images/logos/logo.png' }],
	ogSiteName: 'Diskubre Travel Palawan',
	ogUrl: 'https://www.diskubrepalawan.com',
	twitterCard: 'summary_large_image',
	twitterTitle: 'Diskubre Travel Palawan - Official Website',
	twitterDescription: 'Palawan\'s most trusted travel agency for booking tours, transportation and activities. Book with us today!',
	twitterImage: 'https://www.diskubrepalawan.com/images/logos/logo.png',
	viewport: 'width=device-width, initial-scale=1, maximum-scale=5',
});
</script>

<template>
	<div>
		<MainBanner />
		<CuratedCards />
		<BestSeller />
		<TestimonialsSection />
		<WhyChooseUs />
		<NewsletterSignup />
	</div>
</template>
