<template>
	<div class="banner-wrapper text--white">
		<v-container class="main-container">
			<!-- -----------------------------------------------
            Start Banner
        ----------------------------------------------- -->

			<div class="text-sm-center text-center bg-banner">
				<h1 class="banner-title font-weight-bold">Explore Palawan Island with us.</h1>
				<h2 class="banner-subtitle font-weight-regular">Select wide selection of transfers, tours and activities
					to explore our island, Palawan!</h2>
				<trip-advisor-reviews></trip-advisor-reviews>

				<div class="d-sm-flex d-xs-flex justify-center">
					<v-btn to="/listings/transportation-options" color="primary"
						class="action mr-2 mr-sm-5 mb-5 mb-sm-0 d-sm-flex" size="small" flat>Book a
						Transportation</v-btn>
					<v-btn to="/airport-transfers" color="success" class="action mr-2 mr-sm-5 mb-5 mb-sm-0 d-sm-flex"
						size="small" flat>Airport Transfers</v-btn>
					<v-btn size="small" to="/tours" class="action d-sm-flex mr-2 mr-sm-5 mb-5 mb-sm-0" color="warning"
						flat>Book a Tour</v-btn>
				</div>
			</div>
		</v-container>
	</div>
</template>

<style scoped>
.bg-banner {
	background-color: white;
	opacity: 0.8;
	border-radius: 5px;
	padding: 20px 20px;
}

.banner-title {
	color: black;
	font-size: 30px;
	mix-blend-mode: darken;
}

.banner-subtitle {
	color: black;
	font-size: 20px;
	mix-blend-mode: darken;
}

.action {
	min-width: 200px !important;
	max-width: 200px !important;
	padding: 12px 15px;
}
</style>
