<template>
  <v-container class="my-10">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="9" lg="7">
        <div class="text-center mb-8">
          <h2 class="ui-title font-weight-bold">What Our Customers Say</h2>
          <p class="text-muted">Read testimonials from travelers who have experienced our services</p>
        </div>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col v-for="(testimonial, index) in testimonials" :key="index" cols="12" md="4" class="mb-5">
        <v-card height="100%" class="d-flex flex-column testimonial-card">
          <v-card-text class="flex-grow-1">
            <div class="mb-4">
              <v-rating
                :model-value="testimonial.rating"
                color="amber"
                half-increments
                readonly
                size="small"
                density="compact"
              ></v-rating>
            </div>
            <h4 class="text-subtitle-1 font-weight-bold mb-2">{{ testimonial.title }}</h4>
            <p class="text-body-1 testimonial-text mb-4">"{{ testimonial.text }}"</p>
            <div class="d-flex align-center">
              <v-avatar size="50" class="mr-4">
                <v-img :src="testimonial.avatar" :alt="`${testimonial.name}'s avatar`"></v-img>
              </v-avatar>
              <div>
                <p class="font-weight-bold text-subtitle-1 mb-0">{{ testimonial.name }}</p>
                <p class="text-caption text-primary d-flex align-center">
                  <span v-if="testimonial.countryFlag" class="mr-1">{{ testimonial.countryFlag }}</span>
                  {{ testimonial.location }}
                </p>
                <p class="text-caption text-grey-darken-1">{{ testimonial.travelerType }}</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row class="mt-6">
      <v-col cols="12" class="text-center">
        <v-btn color="primary" variant="outlined" rounded @click="showMoreReviews = !showMoreReviews">
          {{ showMoreReviews ? 'Show Less' : 'View More Reviews' }}
        </v-btn>
      </v-col>
    </v-row>
    
    <v-expand-transition>
      <div v-if="showMoreReviews">
        <v-row class="mt-4">
          <v-col v-for="(testimonial, index) in additionalTestimonials" :key="`more-${index}`" cols="12" md="4" class="mb-5">
            <v-card height="100%" class="d-flex flex-column testimonial-card">
              <v-card-text class="flex-grow-1">
                <div class="mb-4">
                  <v-rating
                    :model-value="testimonial.rating"
                    color="amber"
                    half-increments
                    readonly
                    size="small"
                    density="compact"
                  ></v-rating>
                </div>
                <h4 class="text-subtitle-1 font-weight-bold mb-2">{{ testimonial.title }}</h4>
                <p class="text-body-1 testimonial-text mb-4">"{{ testimonial.text }}"</p>
                <div class="d-flex align-center">
                  <v-avatar size="50" class="mr-4">
                    <v-img :src="testimonial.avatar" :alt="`${testimonial.name}'s avatar`"></v-img>
                  </v-avatar>
                  <div>
                    <p class="font-weight-bold text-subtitle-1 mb-0">{{ testimonial.name }}</p>
                    <p class="text-caption text-primary d-flex align-center">
                      <span v-if="testimonial.countryFlag" class="mr-1">{{ testimonial.countryFlag }}</span>
                      {{ testimonial.location }}
                    </p>
                    <p class="text-caption text-grey-darken-1">{{ testimonial.travelerType }}</p>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </v-container>
</template>

<script setup>
import { onMounted, watch, nextTick, ref, computed } from 'vue';

// Basic state initialization
const store = useBookingStore();
const { reviews } = storeToRefs(store);
const showMoreReviews = ref(false);

// Keep track of selected reviews and their avatars
const selectedReviewIds = ref([]);
const selectedAdditionalReviewIds = ref([]);

// Track used avatar IDs to prevent repetition
const usedAvatarIds = {
  men: new Set(),
  women: new Set()
};

// Fallback testimonials if none are available from the store
const defaultTestimonials = [
  {
    id: 'default-1',
    name: "Sarah Johnson",
    rating: 5,
    title: "Magical Island Hopping Experience",
    text: "Our island hopping tour in El Nido was absolutely magical! The guides were knowledgeable and friendly, making sure we had the best experience.",
    avatar: "https://randomuser.me/api/portraits/women/41.jpg",
    location: "United Kingdom",
    countryFlag: "🇬🇧",
    travelerType: "Family with Children"
  },
  {
    id: 'default-2',
    name: "Michael Liu",
    rating: 5,
    title: "Reliable Airport Transfer",
    text: "The airport transfer service was punctual and comfortable. The driver was very professional and gave us great tips about Palawan.",
    avatar: "https://randomuser.me/api/portraits/men/32.jpg",
    location: "Australia",
    countryFlag: "🇦🇺",
    travelerType: "Business Traveler"
  },
  {
    id: 'default-3',
    name: "Elena Rodriguez",
    rating: 4.5,
    title: "Unforgettable Underground River Tour",
    text: "Our Underground River tour was unforgettable! Everything was well-organized from pickup to drop-off. Highly recommend Diskubre Travel!",
    avatar: "https://randomuser.me/api/portraits/women/63.jpg",
    location: "Spain",
    countryFlag: "🇪🇸",
    travelerType: "Couple"
  }
];

// Additional fallback testimonials for expanded view
const additionalDefaultTestimonials = [
  {
    id: 'default-4',
    name: "James Wilson",
    rating: 5,
    title: "Port Barton Tour Highlight",
    text: "The Port Barton tour was the highlight of our trip. Our guide was fantastic and showed us all the hidden gems. The seafood lunch was delicious!",
    avatar: "https://randomuser.me/api/portraits/men/54.jpg",
    location: "Canada",
    countryFlag: "🇨🇦",
    travelerType: "Group of Friends"
  },
  {
    id: 'default-5',
    name: "Sophie Kim",
    rating: 5,
    title: "Perfect Multi-day Trip Planning",
    text: "I was amazed by how smooth our multi-day tour went. Diskubre arranged everything perfectly - from accommodation to activities. Will definitely book again!",
    avatar: "https://randomuser.me/api/portraits/women/28.jpg",
    location: "South Korea",
    countryFlag: "🇰🇷",
    travelerType: "Solo Traveler" 
  },
  {
    id: 'default-6',
    name: "David Miller",
    rating: 4.5,
    title: "Safe and Reliable Private Van Service",
    text: "We booked a private van from El Nido to Puerto Princesa. The vehicle was clean, driver was safe, and they were right on time. Excellent service!",
    avatar: "https://randomuser.me/api/portraits/men/22.jpg",
    location: "United States",
    countryFlag: "🇺🇸",
    travelerType: "Honeymoon"
  }
];

// Register default testimonial avatar IDs as used
usedAvatarIds.women.add(41);
usedAvatarIds.men.add(32);
usedAvatarIds.women.add(63);
usedAvatarIds.men.add(54);
usedAvatarIds.women.add(28);
usedAvatarIds.men.add(22);

// Helper functions - defined before usage in computed properties

// Get a unique avatar ID for a specific gender
function getUniqueAvatarId(gender) {
  const maxId = 70; // Maximum avatar ID
  const usedIds = usedAvatarIds[gender];
  
  // If all IDs have been used, reset the set
  if (usedIds.size >= maxId) {
    usedIds.clear();
  }
  
  // Generate a unique ID
  let avatarId;
  do {
    avatarId = Math.floor(Math.random() * maxId) + 1;
  } while (usedIds.has(avatarId));
  
  // Add the ID to the used set
  usedIds.add(avatarId);
  
  return avatarId;
}

// Helper function to determine gender from name with higher accuracy
function determineGender(name) {
  if (!name) return Math.random() < 0.5 ? 'men' : 'women';
  
  // Extract first name (assuming first word is the first name)
  const firstName = name.split(' ')[0].toLowerCase().trim();
  
  // Common female names (exact matches)
  const femaleNames = new Set([
    'mary', 'elizabeth', 'patricia', 'jennifer', 'linda', 'barbara', 'susan', 'jessica', 
    'sarah', 'karen', 'lisa', 'nancy', 'betty', 'sandra', 'margaret', 'ashley', 'emily', 
    'donna', 'michelle', 'carol', 'amanda', 'melissa', 'deborah', 'stephanie', 'rebecca', 
    'laura', 'sharon', 'cynthia', 'kathleen', 'amy', 'emma', 'olivia', 'sophia', 'ava', 
    'isabella', 'mia', 'charlotte', 'amelia', 'harper', 'evelyn', 'abigail', 'anna', 
    'ella', 'madison', 'victoria', 'lily', 'grace', 'natalie', 'sofia', 'camila', 'zoe',
    'hannah', 'layla', 'scarlett', 'aria', 'elena', 'maria', 'rosa', 'angela', 'rachel',
    'alyssa', 'jasmine', 'katherine', 'catherine', 'tina', 'sophie', 'julia', 'kim',
    'rhodella', 'jane', 'anne', 'ann', 'anna', 'gigi', 'lisher', 'sophie', 'elena'
  ]);
  
  // Common male names (exact matches)
  const maleNames = new Set([
    'james', 'john', 'robert', 'michael', 'william', 'david', 'richard', 'joseph', 
    'thomas', 'charles', 'christopher', 'daniel', 'matthew', 'anthony', 'mark', 'donald', 
    'steven', 'andrew', 'paul', 'joshua', 'kenneth', 'kevin', 'brian', 'george', 'timothy', 
    'ronald', 'jason', 'edward', 'jeffrey', 'ryan', 'jacob', 'gary', 'nicholas', 'eric', 
    'jonathan', 'stephen', 'larry', 'justin', 'scott', 'brandon', 'benjamin', 'samuel', 
    'gregory', 'alexander', 'patrick', 'frank', 'raymond', 'liam', 'noah', 'oliver', 
    'ethan', 'lucas', 'mason', 'logan', 'caleb', 'henry', 'owen', 'luke', 'jack',
    'jose', 'carlos', 'juan', 'jorge', 'miguel', 'antonio', 'peter', 'max', 'alex',
    'ariel', 'gilbert', 'john', 'keel', 'maxtelfor', 'rodolfo', 'archie', 'tommy'
  ]);
  
  // Exact name match check
  if (femaleNames.has(firstName)) {
    return 'women';
  }
  
  if (maleNames.has(firstName)) {
    return 'men';
  }
  
  // Common female name patterns
  const femalePatterns = [
    { pattern: 'a', position: 'end' },
    { pattern: 'ie', position: 'end' },
    { pattern: 'ette', position: 'end' },
    { pattern: 'elle', position: 'end' },
    { pattern: 'lyn', position: 'end' },
    { pattern: 'ine', position: 'end' },
    { pattern: 'yn', position: 'end' },
    { pattern: 'ella', position: 'end' },
    { pattern: 'issa', position: 'end' },
    { pattern: 'anne', position: 'end' },
    { pattern: 'na', position: 'end' },
    { pattern: 'ia', position: 'end' },
    { pattern: 'ah', position: 'end' }
  ];
  
  // Common male name patterns
  const malePatterns = [
    { pattern: 'son', position: 'end' },
    { pattern: 'er', position: 'end' },
    { pattern: 'an', position: 'end' },
    { pattern: 'en', position: 'end' },
    { pattern: 'on', position: 'end' },
    { pattern: 'ck', position: 'end' },
    { pattern: 'ey', position: 'end' },
    { pattern: 'io', position: 'end' },
    { pattern: 'us', position: 'end' },
    { pattern: 'to', position: 'end' },
    { pattern: 'el', position: 'end' }
  ];
  
  // Check patterns
  const femaleScore = femalePatterns.reduce((score, { pattern, position }) => {
    if (position === 'end' && firstName.endsWith(pattern)) {
      return score + 1;
    }
    return score;
  }, 0);
  
  const maleScore = malePatterns.reduce((score, { pattern, position }) => {
    if (position === 'end' && firstName.endsWith(pattern)) {
      return score + 1;
    }
    return score;
  }, 0);
  
  // Determine gender based on pattern matching scores
  if (femaleScore > maleScore) {
    return 'women';
  } else if (maleScore > femaleScore) {
    return 'men';
  } else {
    // Some specific first letter heuristics as a fallback
    const firstChar = firstName.charAt(0);
    if (['j', 'm', 'd', 'r', 't', 'c', 'g'].includes(firstChar)) {
      return Math.random() < 0.7 ? 'men' : 'women'; // 70% chance for male
    } else if (['a', 'e', 's', 'l', 'k', 'h'].includes(firstChar)) {
      return Math.random() < 0.7 ? 'women' : 'men'; // 70% chance for female
    } else {
      // If still uncertain, random with slight bias based on name length
      // Shorter names tend to be male in many cultures
      return firstName.length <= 4 ? 'men' : 'women';
    }
  }
}

// List of traveler types
const travelerTypes = [
  'Solo Traveler',
  'Couple',
  'Family with Children',
  'Group of Friends',
  'Business Traveler',
  'Senior Couple',
  'Extended Family',
  'Honeymoon'
];

// Function to get random traveler type
function getRandomTravelerType() {
  const randomIndex = Math.floor(Math.random() * travelerTypes.length);
  return travelerTypes[randomIndex];
}

// List of countries with their flags
const countries = [
  { name: 'United States', code: 'US', flag: '🇺🇸' },
  { name: 'United Kingdom', code: 'GB', flag: '🇬🇧' },
  { name: 'Australia', code: 'AU', flag: '🇦🇺' },
  { name: 'Canada', code: 'CA', flag: '🇨🇦' },
  { name: 'Germany', code: 'DE', flag: '🇩🇪' },
  { name: 'France', code: 'FR', flag: '🇫🇷' },
  { name: 'Japan', code: 'JP', flag: '🇯🇵' },
  { name: 'South Korea', code: 'KR', flag: '🇰🇷' },
  { name: 'China', code: 'CN', flag: '🇨🇳' },
  { name: 'India', code: 'IN', flag: '🇮🇳' },
  { name: 'Brazil', code: 'BR', flag: '🇧🇷' },
  { name: 'Mexico', code: 'MX', flag: '🇲🇽' },
  { name: 'Spain', code: 'ES', flag: '🇪🇸' },
  { name: 'Italy', code: 'IT', flag: '🇮🇹' },
  { name: 'Russia', code: 'RU', flag: '🇷🇺' },
  { name: 'Switzerland', code: 'CH', flag: '🇨🇭' },
  { name: 'Sweden', code: 'SE', flag: '🇸🇪' },
  { name: 'Netherlands', code: 'NL', flag: '🇳🇱' },
  { name: 'Singapore', code: 'SG', flag: '🇸🇬' },
  { name: 'New Zealand', code: 'NZ', flag: '🇳🇿' }
];

// Function to get random country
function getRandomCountry() {
  const randomIndex = Math.floor(Math.random() * countries.length);
  return countries[randomIndex];
}

// Function to get country flag from country name
function getCountryFlag(countryName) {
  const country = countries.find(c => 
    c.name.toLowerCase() === countryName.toLowerCase()
  );
  return country ? country.flag : '';
}

// Helper to get random items from an array without repetition
function getRandomItems(array, count) {
  // Create a copy to avoid modifying the original array
  const arrayCopy = [...array];
  const result = [];
  
  // If array is too small, return all items
  if (arrayCopy.length <= count) {
    return arrayCopy;
  }
  
  // Select random items
  for (let i = 0; i < count; i++) {
    const randomIndex = Math.floor(Math.random() * arrayCopy.length);
    result.push(arrayCopy[randomIndex]);
    arrayCopy.splice(randomIndex, 1);
  }
  
  return result;
}

// Process reviews from the store
const processedReviews = computed(() => {
  if (!reviews.value || !Array.isArray(reviews.value) || reviews.value.length === 0) {
    return [...defaultTestimonials, ...additionalDefaultTestimonials];
  }
  
  const filteredReviews = reviews.value.filter(review => review.reviewRating >= 4);
  
  // Map reviews to testimonial format with consistent avatars and info
  return filteredReviews.map((review, index) => {
    // Determine gender based on name
    const authorName = review.author || '';
    const gender = determineGender(authorName);
    
    // Get a unique avatar ID that's consistent for this review
    const reviewId = review.author + review.reviewTitle;
    
    // Get random country and flag if nationality not provided
    const countryInfo = getRandomCountry();
    const location = review.nationality || countryInfo.name;
    
    return {
      id: index, // Use index as ID for selection
      reviewId: reviewId, // Original review identifier
      name: authorName || 'Anonymous',
      rating: review.reviewRating || 5,
      title: review.reviewTitle || 'Great Experience',
      text: review.reviewContent && review.reviewContent.length > 200 
        ? review.reviewContent.substring(0, 200) + '...' 
        : review.reviewContent || 'Great experience with Diskubre Travel!',
      avatar: review.avatar || 
             `https://randomuser.me/api/portraits/${gender}/${getUniqueAvatarId(gender)}.jpg`,
      location: location,
      countryFlag: review.nationality ? getCountryFlag(location) : countryInfo.flag,
      travelerType: getRandomTravelerType()
    };
  });
});

// Select or generate main testimonials
const testimonials = computed(() => {
  const allReviews = processedReviews.value;
  
  // If we have saved selections and they're valid, use them
  if (selectedReviewIds.value.length === 3) {
    const selected = [];
    
    for (const id of selectedReviewIds.value) {
      const review = allReviews.find(r => r.id === id);
      if (review) {
        selected.push(review);
      }
    }
    
    // If we found all selected reviews, return them
    if (selected.length === 3) {
      return selected;
    }
  }
  
  // Otherwise, select new random reviews
  const randomReviews = getRandomItems(allReviews, 3);
  selectedReviewIds.value = randomReviews.map(r => r.id);
  return randomReviews;
});

// For the expandable section - next 3 random reviews
const additionalTestimonials = computed(() => {
  const allReviews = processedReviews.value;
  const mainIds = new Set(selectedReviewIds.value);
  
  // Filter out reviews already shown in main section
  const remainingReviews = allReviews.filter(r => !mainIds.has(r.id));
  
  // If we have saved selections and they're valid, use them
  if (selectedAdditionalReviewIds.value.length === 3) {
    const selected = [];
    
    for (const id of selectedAdditionalReviewIds.value) {
      const review = allReviews.find(r => r.id === id && !mainIds.has(r.id));
      if (review) {
        selected.push(review);
      }
    }
    
    // If we found all selected reviews, return them
    if (selected.length === 3) {
      return selected;
    }
  }
  
  // Otherwise, select new random reviews
  const randomReviews = getRandomItems(remainingReviews, 3);
  selectedAdditionalReviewIds.value = randomReviews.map(r => r.id);
  return randomReviews;
});

// Lifecycle hooks - at the end to ensure all refs and computed properties are defined
onMounted(() => {
  nextTick(() => {
    try {
      // Load saved review selections from localStorage
      const savedMainReviews = localStorage.getItem('testimonials-main');
      const savedAdditionalReviews = localStorage.getItem('testimonials-additional');
      
      if (savedMainReviews) {
        selectedReviewIds.value = JSON.parse(savedMainReviews);
      }
      
      if (savedAdditionalReviews) {
        selectedAdditionalReviewIds.value = JSON.parse(savedAdditionalReviews);
      }
      
      // Load saved avatar IDs
      const savedAvatarIds = localStorage.getItem('testimonials-avatars');
      if (savedAvatarIds) {
        const parsed = JSON.parse(savedAvatarIds);
        
        // Convert arrays back to Sets
        if (parsed.men && Array.isArray(parsed.men)) {
          parsed.men.forEach(id => usedAvatarIds.men.add(id));
        }
        
        if (parsed.women && Array.isArray(parsed.women)) {
          parsed.women.forEach(id => usedAvatarIds.women.add(id));
        }
      }
    } catch (error) {
      console.error('Error loading saved testimonials:', error);
      // If error, we'll generate new random selections
    }
  });
});

// Watch changes - must be at the end after all refs and computed properties
const saveTestimonials = () => {
  try {
    // Only save if we have valid selections
    if (selectedReviewIds.value.length > 0) {
      localStorage.setItem('testimonials-main', JSON.stringify(selectedReviewIds.value));
    }
    
    if (selectedAdditionalReviewIds.value.length > 0) {
      localStorage.setItem('testimonials-additional', JSON.stringify(selectedAdditionalReviewIds.value));
    }
    
    // Save avatar IDs
    localStorage.setItem('testimonials-avatars', JSON.stringify({
      men: Array.from(usedAvatarIds.men),
      women: Array.from(usedAvatarIds.women)
    }));
  } catch (error) {
    console.error('Error saving testimonials:', error);
  }
};

// Delay the watch setup to ensure everything is initialized
nextTick(() => {
  watch([selectedReviewIds, selectedAdditionalReviewIds], saveTestimonials, { deep: true });
});
</script>

<style scoped>
.testimonial-card {
  transition: transform 0.3s ease;
  border-radius: 12px;
  position: relative;
  padding: 10px;
}

.testimonial-card:before {
  content: '"';
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 60px;
  color: rgba(0,0,0,0.1);
  font-family: Georgia, serif;
  line-height: 1;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.testimonial-text {
  font-style: italic;
  line-height: 1.6;
  position: relative;
  z-index: 1;
}
</style> 