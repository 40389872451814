<template>
  <v-container class="py-10">
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="7">
        <v-card class="newsletter-card px-6 py-8">
          <v-row align="center">
            <v-col cols="12" md="6">
              <h2 class="text-h5 font-weight-bold mb-2">Get Travel Inspiration & Special Offers</h2>
              <p class="mb-4">Subscribe to our newsletter for travel tips, exclusive deals, and Palawan highlights</p>
            </v-col>
            <v-col cols="12" md="6">
              <v-form @submit.prevent="submitNewsletter" v-model="valid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="Your Email"
                      variant="outlined"
                      density="compact"
                      hide-details="auto"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      type="submit"
                      color="primary"
                      block
                      :loading="loading"
                      :disabled="!valid || loading"
                    >
                      Subscribe
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
          
          <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
            timeout="3000"
          >
            {{ snackbarText }}
          </v-snackbar>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
const valid = ref(false);
const loading = ref(false);
const email = ref('');
const snackbar = ref(false);
const snackbarText = ref('');
const snackbarColor = ref('');

const emailRules = [
  v => !!v || 'Email is required',
  v => /.+@.+\..+/.test(v) || 'Email must be valid'
];

const submitNewsletter = async () => {
  if (!valid.value) return;
  
  loading.value = true;
  
  try {
    // Simulate API call - replace with actual implementation
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // Success response
    snackbarText.value = 'Thank you for subscribing to our newsletter!';
    snackbarColor.value = 'success';
    email.value = '';
  } catch (error) {
    // Error response
    snackbarText.value = 'Something went wrong. Please try again.';
    snackbarColor.value = 'error';
  } finally {
    loading.value = false;
    snackbar.value = true;
  }
};
</script>

<style scoped>
.newsletter-card {
  border-radius: 16px;
  background: linear-gradient(to right, #ffffff, #f5f7fa);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
}
</style> 